import { Controller } from "@hotwired/stimulus";
import Pickr from "@simonwep/pickr";

export default class extends Controller {
  static targets = ["element", "input", "clear", "hexPreview", "divPreview", "eyeDropper"];

  connect() {    
    const input = this.inputTarget;
    const clearButton = this.clearTarget;
    const hexPreview = this.hexPreviewTarget;
    const circlePreview = this.divPreviewTarget;
    const eyeDropper = this.eyeDropperTarget;

    const useColorAsBackgroundColor = document.getElementsByClassName(`${input.id}-as-background-color`);
    const useColorAsTextColor = document.getElementsByClassName(`${input.id}-as-text-color`);

    hexPreview.innerHTML = input.value || 'Transparent';
    circlePreview.style.backgroundColor = input.value;

    clearButton.addEventListener("click", function (e) {
      const color = "#e5e7eb";
      input.value = color;
      circlePreview.style.backgroundColor = color;

      e.preventDefault();
    });

    if (!window.EyeDropper) {
      eyeDropper.remove()
    } else {
      eyeDropper.addEventListener("click", () => {
        this.startEyeDropper(input, hexPreview, circlePreview)
      });
    }

    Pickr.create({
      el: this.elementTarget,
      theme: "nano",
      comparison: false,
      default: input.value,
      useAsButton: true,
      swatches: [
        "rgb(245, 246, 250)",
        "rgb(220, 221, 225)",
        "rgb(127, 143, 166)",
        "rgb(113, 128, 147)",
        "rgb(53, 59, 72)",
        "rgb(47, 54, 64)",
        "rgb(25, 42, 86)",
        "rgb(244, 67, 54)",
        "rgb(233, 30, 99)",
        "rgb(156, 39, 176)",
        "rgb(103, 58, 183)",
        "rgb(63, 81, 181)",
        "rgb(33, 150, 243)",
        "rgb(3, 169, 244)",
        "rgb(0, 188, 212)",
        "rgb(0, 150, 136)",
        "rgb(76, 175, 80)",
        "rgb(139, 195, 74)",
        "rgb(205, 220, 57)",
        "rgb(255, 235, 59)",
        "rgb(255, 193, 7)",
      ],
      components: {
        preview: true,
        opacity: false,
        hue: false,
        interaction: {
          hex: false,
          rgba: false,
          hsla: false,
          hsva: false,
          cmyk: false,
          input: true,
          clear: false,
          save: false,
        },
      },
    }).on("change", (color) => {
      input.value = color.toHEXA();
      hexPreview.innerHTML = color.toHEXA()
      circlePreview.style.backgroundColor = color.toHEXA()

      if (useColorAsBackgroundColor[0] !== undefined) {
        this.styleAdditionalElements(useColorAsBackgroundColor, color.toHEXA(), 'backgroundColor');
      }
      if (useColorAsTextColor[0] !== undefined) {
        this.styleAdditionalElements(useColorAsTextColor, color.toHEXA(), 'color');
      }

      if (input.classList.contains("has-apply-all-link")) {
        const applyAllLink = input.parentElement.parentElement.querySelector(".apply-all-link")

        applyAllLink.href = `/backoffice/button_settings/apply_all?column_name=${
          applyAllLink.getAttribute('name')
        }&column_value=${color.toHEXA().toString().replaceAll("#", "")}`;
      }
    });
  }

  styleAdditionalElements(inputs, color, attr) {
    for (var i = 0; i !== inputs.length; i++) {
      inputs[i].style[attr] = color;
    }
  }

  startEyeDropper(input, hexPreview, circlePreview) {
    const eyeDropper = new EyeDropper();
  
    eyeDropper
      .open()
      .then((result) => {
        input.value = result.sRGBHex
        hexPreview.innerHTML = result.sRGBHex
        circlePreview.style.backgroundColor = result.sRGBHex
      })
      .catch((e) => {
        console.log("Something went wrong...")
      });
  }
}
