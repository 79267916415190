import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    inputId: String,
  };
  static targets = ["icon"];

  select() {
    console.log(this.inputIdValue);
    document.getElementById(this.inputIdValue).value = this.iconTarget.dataset.iconId;
    document.getElementById(`${this.inputIdValue}-link`).innerHTML = `
      <div class="flex items-center space-x-2">
        <div class="w-8 h-8">
          <img src="${this.iconTarget.dataset.iconUrl}" class="w-full h-full" />
        </div>
        <div class="flex items-center space-x-2">
          <span class="text-sm text-black">${this.iconTarget.dataset.iconName}</span>
        </div>
      </div>
    `;
    document.querySelector(".close-modal").click();
  }
}
